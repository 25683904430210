import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import classNames from 'classnames'
import {classes} from './label-with-value.st.css'
import {LabelWithValueProps} from './index'

export const LabelWithValue = ({label, labelClassName, valueClassName, value}: LabelWithValueProps) =>
  value ? (
    <div className={classes.labelWithValue}>
      <Text tagName="p" className={classNames(classes.smallText, labelClassName ?? classes.smallTextColor)}>
        {label}
      </Text>
      <Text tagName="p" className={classNames(classes.value, valueClassName ?? classes.valueColor)}>
        {value}
      </Text>
    </div>
  ) : null
