import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Divider} from '../divider'
import {TicketProps} from './interfaces'
import {AddToWalletButton} from './add-to-wallet-button'
import s from './ticket.scss'

export const Ticket = ({
  t,
  ticket: {
    ticketNumber,
    name,
    walletPassUrl,
    guestDetails: {firstName, lastName},
  },
  showBottomDivider,
  staticsBaseUrl,
  language,
  onDownload,
}: TicketProps) => (
  <div data-hook={DH.APPLE_WALLET_TICKET}>
    <div className={s.ticketInfo} data-hook={DH.APPLE_WALLET_TICKET_NAME}>
      {name}
    </div>
    <div className={s.ticketInfo} data-hook={DH.APPLE_WALLET_TICKET_NUMBER}>
      {ticketNumber}
    </div>
    <div className={s.ticketInfo} data-hook={DH.APPLE_WALLET_TICKET_BUYER}>{`${firstName} ${lastName}`}</div>
    <AddToWalletButton
      className={s.addToWallet}
      t={t}
      language={language}
      walletPassUrl={walletPassUrl}
      staticsBaseUrl={staticsBaseUrl}
      onClick={onDownload}
    />
    {showBottomDivider && <Divider />}
  </div>
)
