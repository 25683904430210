import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import alignmentClasses from '../../alignment/alignment.scss'
import {RegistrationButton} from '../../registration-button'
import {ShortDateLocation} from '../short-date-location'
import {Title} from '../title'
import {Description} from '../description'
import s from './classic-header.scss'
import {Image} from './image'
import {ClassicHeaderLayoutProps} from './index'

export const ClassicLayout: React.FC<ClassicHeaderLayoutProps> = ({imageVisible, onClickRegistrationButton}) => {
  return (
    <div className={classNames(s.wrapper, imageVisible && s.image)}>
      <div className={s.headerBackground} />
      <div className={s.container} data-hook={DH.header}>
        <div
          className={classNames(s.content, s.classicHeaderLayout, alignmentClasses.headerAlignment, {
            [s.contentWithImage]: imageVisible,
          })}
        >
          <ShortDateLocation />
          <Title className={s.eventTitle} />
          <Description />
          <RegistrationButton onClick={onClickRegistrationButton} />
        </div>
        <Image />
      </div>
    </div>
  )
}
