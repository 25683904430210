import React from 'react'
import {Text, Tabs} from 'wix-ui-tpa/cssVars'
import classNames from 'classnames'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {CloseButton} from '../close-button'
import {classes} from './header.st.css'
import {HeaderProps} from './index'

export const Header = ({
  title,
  closeButtonAsButton,
  className,
  titleClassName,
  tabs,
  activeTabIndex,
  closeModal,
  onTabClick,
}: HeaderProps) => {
  const {isMobile} = useEnvironment()
  const hasTabs = tabs?.length
  const mobileWithTabs = isMobile && hasTabs

  return (
    <div className={classNames(classes.header, className, {[classes.mobileHeaderWithTabs]: mobileWithTabs})}>
      {title ? (
        <Text
          tagName="h1"
          className={classNames(classes.title, titleClassName, {[classes.mobileTitleWithTabs]: mobileWithTabs})}
        >
          {title}
        </Text>
      ) : null}
      {hasTabs ? (
        <Tabs className={classes.tabs} items={tabs} activeTabIndex={activeTabIndex} onTabClick={onTabClick} />
      ) : null}
      {closeModal ? (
        <CloseButton
          className={classes.headerCloseButton}
          closeButtonAsButton={closeButtonAsButton}
          closeModal={closeModal}
        />
      ) : null}
    </div>
  )
}
