import loadable from '@wix/yoshi-flow-editor/loadable'

export const FormEntry = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "form" */ '../router/form-entry'),
)

export const StatusScreensEntry = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "status-screens" */ '../router/status-screens-entry'),
)

export const SeatingPlanModal = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "seating-plan-modal" */ '../modals/seating-plan'),
)
