import {IStyleParam} from '@wix/tpa-settings'
import {
  EventDetailsAlignment,
  EventDetailsButtonStyle,
  EventDetailsImageRatio,
  HEADER_IMAGE_ALIGNMENT,
  HEADER_LAYOUT,
  IMAGE_SCALING,
} from '@wix/wix-events-commons-statics'
import {remapColor, remapFont} from '../../commons/custom-css-utils'
import stylesParams, {DetailsStyleParams} from './stylesParams'

interface CustomCssVarsParams {
  styleParams: DetailsStyleParams
}

export const customCssVars = ({styleParams}: CustomCssVarsParams) => {
  const RTL = styleParams.booleans.__IS_RTL__

  const styles = {
    get: (param: IStyleParam) => styleParams.booleans[param.key] ?? styleParams.numbers[param.key],
  }

  const isShortDateLocationVisible = () => styles.get(stylesParams.shortDateLocationVisible)

  const isDescriptionVisible = () => styles.get(stylesParams.descriptionVisible)

  const isMembersVisible = () => styles.get(stylesParams.membersVisible)

  const isMembershipsVisible = () => styles.get(stylesParams.membershipsVisible)

  const isGroupVisible = () => styles.get(stylesParams.groupVisible)

  const isAboutSectionVisible = () => styles.get(stylesParams.aboutSectionVisible)

  const isScheduleVisible = () => styles.get(stylesParams.scheduleVisible)

  const isFitImage = () => styles.get(stylesParams.imageScaling) === IMAGE_SCALING.FIT

  const isSquareImage = () =>
    styles.get(stylesParams.headerLayout) === HEADER_LAYOUT.SIDE_BY_SIDE ||
    styles.get(stylesParams.imageRatio) === EventDetailsImageRatio['1:1']

  const isSocialShareVisible = () => styles.get(stylesParams.socialShareVisible)

  const getTextAlignment = (param: IStyleParam) => {
    const alignment = styles.get(param)
    if (alignment === EventDetailsAlignment.LEFT) {
      return 'left'
    } else if (alignment === EventDetailsAlignment.RIGHT) {
      return 'right'
    }
    return 'center'
  }

  const isLeftAligned = (param: IStyleParam) => styles.get(param) === EventDetailsAlignment.LEFT
  const isRightAligned = (param: IStyleParam) => styles.get(param) === EventDetailsAlignment.RIGHT
  const isCenterAligned = (param: IStyleParam) => styles.get(param) === EventDetailsAlignment.CENTER

  const getTimeAndLocationContainerDir = () => {
    if (isCenterAligned(stylesParams.contentAlignment)) {
      return 'column'
    }
    return RTL ? 'row-reverse' : 'row'
  }

  const getContentListItemPosition = () => {
    if (RTL) {
      return isRightAligned(stylesParams.contentAlignment) ? 'outside' : 'inside'
    }
    return isLeftAligned(stylesParams.contentAlignment) ? 'outside' : 'inside'
  }

  const isPageRegistrationButtonVisible = () => styles.get(stylesParams.pageRegistrationButtonVisible)

  const isHeaderRegistrationButtonVisible = () => styles.get(stylesParams.headerRegistrationButtonVisible)

  const isRoundedButton = (buttonStyle: EventDetailsButtonStyle) =>
    [EventDetailsButtonStyle.FULL_ROUNDED, EventDetailsButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

  const isHollowButton = (buttonStyle: EventDetailsButtonStyle) =>
    [EventDetailsButtonStyle.HOLLOW, EventDetailsButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

  const isRegistrationButtonHollow = () => isHollowButton(styles.get(stylesParams.buttonStyle))
  const isRegistrationButtonRounded = () => isRoundedButton(styles.get(stylesParams.buttonStyle))

  const isFormButtonHollow = () => isHollowButton(styles.get(stylesParams.formButtonStyle))
  const isFormButtonRounded = () => isRoundedButton(styles.get(stylesParams.formButtonStyle))

  const isMapVisible = () => styles.get(stylesParams.mapVisible)

  const isReadMoreButtonVisible = () => styles.get(stylesParams.readMoreButtonVisible)

  return {
    shortDateLocationVisibility: isShortDateLocationVisible() ? 'block' : 'none',
    descriptionVisibility: isDescriptionVisible() ? 'block' : 'none',
    membersVisibility: isMembersVisible() ? 'block' : 'none',
    membershipsVisibility: isMembershipsVisible() ? 'block' : 'none',
    aboutSectionLargerBottomMargin: isMembershipsVisible() ? '42px' : '0',
    groupVisibility: isGroupVisible() ? 'block' : 'none',
    aboutSectionVisibility: isAboutSectionVisible() ? 'block' : 'none',
    groupActivityWithAboutSpacingMobile: isAboutSectionVisible() ? '24px' : '32px',
    groupActivityWithAboutSpacingDesktop: isAboutSectionVisible() ? '28px' : '32px',
    headerImageFlexDirection:
      styles.get(stylesParams.headerImageAlignment) === HEADER_IMAGE_ALIGNMENT.LEFT ? 'row' : 'row-reverse',
    isSquareImage: isSquareImage() ? 1 : 0,
    fitImageVisibility: isFitImage() ? 'flex' : 'none',
    coverImageVisibility: !isFitImage() ? 'flex' : 'none',
    scheduleVisibility: isScheduleVisible() ? 'block' : 'none',
    socialShareVisibility: isSocialShareVisible() ? 'block' : 'none',
    headerTextAlignment: getTextAlignment(stylesParams.headerAlignment),
    contentTextAlignment: getTextAlignment(stylesParams.contentAlignment),
    contentListItemPosition: getContentListItemPosition(),
    isContentCentered: isCenterAligned(stylesParams.contentAlignment) ? 1 : 0,
    timeAndLocationContainerDir: getTimeAndLocationContainerDir(),
    pageRegistrationButtonVisibility: isPageRegistrationButtonVisible() ? 'block' : 'none',
    headerRegistrationButtonVisibility: isHeaderRegistrationButtonVisible() ? 'block' : 'none',
    mapVisibility: isMapVisible() ? 'block' : 'none',
    readMoreButtonVisibility: isReadMoreButtonVisible() ? 'inline-block' : 'none',
    aboutSectionWrapperVisibility: isReadMoreButtonVisible() ? 'block' : 'none',
    aboutSectionWrapperMaxHeight: isReadMoreButtonVisible() ? '14em' : 'auto',
    aboutSectionWrapperHeight: isReadMoreButtonVisible() ? 'calc(14em - 36px)' : 'auto',

    // Primary registration button - details page
    ...remapFont('registrationButtonFont', isRegistrationButtonHollow() ? 'rsvpHollowButtonFont' : 'rsvpButtonFont'),
    ...remapColor(
      'registrationButtonColor',
      isRegistrationButtonHollow() ? 'rsvpHollowButtonColor' : 'rsvpButtonColor',
    ),
    ...remapColor(
      'registrationButtonBackgroundColor',
      isRegistrationButtonHollow() ? null : 'rsvpButtonBackgroundColor',
    ),
    ...remapColor('registrationButtonBorderColor', isRegistrationButtonHollow() ? 'rsvpButtonBorderColor' : null),
    registrationButtonBorderWidth: isRegistrationButtonHollow() ? 'var(--rsvpButtonBorderWidth)' : '0',
    registrationButtonBorderRadius: isRegistrationButtonRounded()
      ? 'var(--rsvpButtonRoundedBorderRadius)'
      : 'var(--rsvpButtonBorderRadius)',

    // Secondary registration button - details page. Share some styles with primary, form/border
    ...remapColor(
      'secondaryRegistrationButtonColor',
      isRegistrationButtonHollow() ? 'secondRsvpHollowButtonColor' : 'secondRsvpButtonColor',
    ),
    ...remapColor(
      'secondaryRegistrationButtonBackgroundColor',
      isRegistrationButtonHollow() ? null : 'secondRsvpButtonBackgroundColor',
    ),
    ...remapColor(
      'secondaryRegistrationButtonBorderColor',
      isRegistrationButtonHollow() ? 'secondRsvpButtonBorderColor' : null,
    ),

    // Any other buttons (eg read more, Membership offers, etc) - details page
    ...remapColor(
      'additionalButtonColor',
      isRegistrationButtonHollow() ? 'secondRsvpButtonBorderColor' : 'secondRsvpButtonBackgroundColor',
    ),

    // Primary form button - form page
    ...remapFont('formButtonFont', isFormButtonHollow() ? 'formHollowButtonFont' : 'formFullButtonFont'),
    ...remapColor('formButtonColor', isFormButtonHollow() ? 'formHollowButtonColor' : 'formFullButtonColor'),
    ...remapColor('formButtonResolvedBorderColor', isFormButtonHollow() ? 'formButtonBorderColor' : null),
    formButtonResolvedBorderWidth: isFormButtonHollow() ? 'var(--formButtonBorderWidth)' : '0',
    formButtonResolvedBorderRadius: isFormButtonRounded()
      ? 'var(--formButtonRoundedBorderRadius)'
      : 'var(--formButtonBorderRadius)',
    ...remapColor('formButtonResolvedBackgroundColor', isFormButtonHollow() ? null : 'formButtonBackgroundColor'),

    // Other form buttons - form page
    ...remapColor(
      'formAdditionalButtonColor',
      isFormButtonHollow() ? 'formButtonBorderColor' : 'formButtonBackgroundColor',
    ),
  }
}
