import {LocalStorage} from '@wix/panda-js-utils'
import React from 'react'
import {TPAComponentsProvider} from 'wix-ui-tpa/cssVars'
import classNames from 'classnames'
import {withWixSdk} from '@wix/yoshi-flow-editor'
import {viewerDataHooks as DH} from '@wix/wix-events-data-hooks'
import {ErrorPage} from '../../../../../commons/components/error-page'
import {Direction} from '../../../../../commons/constants/html'
import {DatesProvider} from '../../../../../commons/hooks/dates'
import {isMobile, isMobileSize, isResponsive, isPreview} from '../../../../../commons/selectors/environment'
import {setCurrentRoute} from '../../../../../commons/utils/current-path-storage'
import {ROOT_COMPONENT_ID} from '../../constants'
import {DetailsPageRuntimeContext} from '../runtime-context/context'
import {Modals} from '../modals'
import {Router} from '../router'
import {FocusHandler} from '../../../../../commons/components/focus-handler'
import {MOBILE_SETTING_POSTFIX} from '../../selectors/settings'
import {DetailsPageAppProps} from './interfaces'
import s from './app.scss'

class DetailsPage extends React.Component<DetailsPageAppProps> {
  removePageNavigationListener: Function = () => {}

  componentDidUpdate(prevProps: DetailsPageAppProps) {
    const {actions, host, state} = this.props
    if (actions && host && state) {
      if (isPreview(state) && host.formFactor !== prevProps.host.formFactor) {
        actions.setFormFactor(host.formFactor)
      }
      if (state.navigation.shouldNavigateBack) {
        window.history.back()
      }
    }
  }

  componentDidMount() {
    if (this.props.sdk?.Wix) {
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.STYLE_PARAMS_CHANGE, this.updateStyleParams)
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.SETTINGS_UPDATED, this.updateSettings)
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.SITE_PUBLISHED, this.onSitePublished)
      this.addPageNavigationListener()
    }
  }

  componentWillUnmount() {
    if (this.props.sdk?.Wix) {
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.STYLE_PARAMS_CHANGE, this.updateStyleParams)
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.SETTINGS_UPDATED, this.updateSettings)
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.SITE_PUBLISHED, this.onSitePublished)
      this.removePageNavigationListener()
    }
  }

  addPageNavigationListener = async () => {
    this.removePageNavigationListener = await LocalStorage.monitorMessage('NAVIGATE_PAGE', ({route}) => {
      setCurrentRoute(route)
      this.props.actions.navigateViaEditor(route)
    })
  }

  updateStyleParams = styleParams => {
    this.props.actions.updateStyleParams(styleParams)
  }

  updateSettings = action => {
    this.props.actions.updateSettings(action)
  }

  onSitePublished = () => {
    this.props.actions.publishComponentSettings()
  }

  render() {
    const {state, isRTL, host} = this.props

    if (!state) {
      return <ErrorPage viewMode={host.viewMode} />
    }

    const mobile = isMobile(state) || isMobileSize(state, host)
    const responsive = isResponsive(host)

    mergeSettings(this.props, mobile)

    return (
      <DetailsPageRuntimeContext.Provider value={this.props}>
        <TPAComponentsProvider value={{mobile, rtl: isRTL}}>
          <DatesProvider dates={state.dates}>
            <div
              dir={isRTL ? Direction.RTL : Direction.LTR}
              id={ROOT_COMPONENT_ID}
              className={s.root}
              data-hook={DH.ROOT_NODE}
            >
              <Modals />
              <FocusHandler />
              <div
                className={classNames(s.root, {
                  'events-details-page-mobile': mobile,
                  'events-details-page-desktop': !mobile,
                  'events-details-page-responsive': responsive,
                  'events-details-page-nonresponsive': !responsive,
                })}
              >
                <Router />
              </div>
            </div>
          </DatesProvider>
        </TPAComponentsProvider>
      </DetailsPageRuntimeContext.Provider>
    )
  }
}

const mergeSettings = (props: DetailsPageAppProps, mobile: boolean) => {
  const sources = [props.host.style.styleParams.numbers, props.host.style.styleParams.booleans]

  for (const source of sources) {
    props.state.component.settings = {
      ...props.state.component.settings,
      ...source,
    }
  }

  if (mobile) {
    for (const source of sources) {
      Object.entries(source).forEach(([key, value]) => {
        if (key.endsWith(MOBILE_SETTING_POSTFIX)) {
          props.state.component.settings[key.substring(0, key.length - MOBILE_SETTING_POSTFIX.length)] = value
        }
      })
    }
  }
}

export const App = withWixSdk<any>(DetailsPage)
