import React, {useEffect, useState} from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import xss from 'xss'
import {getAboutText} from '@wix/wix-events-commons-statics'
import cl from '../../classnames.scss'
import {usePageStateSelector} from '../../../hooks/state'
import {isPaidPlansVisible} from '../../../selectors/paid-plans'
import {isGroupActivityVisible} from '../../../selectors/groups'
import {Subtitle} from '../../subtitle'
import {useSettings} from '../../../../hooks/settings'
import {settingsParams} from '../../../../settingsParams'
import {getElementStyle} from '../../../../../../commons/utils/dom'
import s from './new-about-section.scss'
import {NewAboutSectionProps} from '.'

export const NewAboutSection = ({compact}: NewAboutSectionProps) => {
  const WRAPPER_ID = 'events-details-page-about-wrapper'
  const INNER_ID = 'events-details-page-about-inner'
  const {isMobile, isEditor} = useEnvironment()
  const {t} = useTranslation()
  const readMoreButtonExtraMargin = usePageStateSelector(
    state => isPaidPlansVisible(state) && !isGroupActivityVisible(state),
  )
  const componentSettings = usePageStateSelector(state => state.component.settings)
  const settings = useSettings(componentSettings)
  const aboutTitleText = settings.get(settingsParams.aboutTitleText)
  const eventId = usePageStateSelector(state => state.event.id)
  const aboutText = usePageStateSelector(state => getAboutText(state.event))
  const readMoreText = settings.get(settingsParams.readMoreText)
  const readLessText = settings.get(settingsParams.readLessText)
  const [collapsed, setCollapsed] = useState(true)
  const [buttonVisible, setButtonVisible] = useState(false)

  useEffect(
    () => {
      const wrapper = document.getElementById(WRAPPER_ID)
      const inner = document.getElementById(INNER_ID)
      const wrapperHeight = parseInt(getElementStyle(wrapper, 'height'), 10)
      const innerHeight = parseInt(getElementStyle(inner, 'height'), 10)

      setButtonVisible(inner && wrapper && innerHeight > wrapperHeight)
    },
    isEditor ? undefined : [],
  )

  return (
    <div
      className={classNames(
        s.container,
        {
          [s.mobileContainer]: isMobile,
          [s.largerBottomMargin]: readMoreButtonExtraMargin,
        },
        cl.evTextFont,
        cl.evTextColor,
      )}
      data-hook={DH.ABOUT_SECTION}
    >
      <Subtitle
        className={classNames({[s.mobileSubtitle]: isMobile})}
        dataHook="about"
        text={aboutTitleText}
        compact={compact}
      />
      <div
        id={WRAPPER_ID}
        data-hook={DH.ABOUT_SECTION_WRAPPER}
        className={classNames(s.wrapper, {
          [s.expanded]: !collapsed,
          [s.wrapperNoShadow]: !buttonVisible,
          [s.buttonVisible]: buttonVisible,
        })}
      >
        <div
          id={INNER_ID}
          className={s.aboutText}
          data-hook={DH.ABOUT_SECTION_TEXT}
          role="text"
          key={eventId}
          dangerouslySetInnerHTML={{
            __html: xss(isEditor && !aboutText ? t('demoEvent_aboutSectionText') : aboutText),
          }}
        />
      </div>
      <button
        className={classNames(s.readMore, {
          [s.hidden]: !buttonVisible,
        })}
        onClick={() => setCollapsed(!collapsed)}
        data-hook={DH.ABOUT_SECTION_BUTTON}
      >
        {collapsed ? readMoreText : readLessText}
      </button>
    </div>
  )
}
